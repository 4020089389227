.twoFields {
  display: flex;
  gap: 1rem;
  margin-bottom: 1em;
  align-items: baseline;
}

.wagner-form {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
}

.sanelian-form {
  display: flex;
  flex-direction: column;
  text-align: left;
  padding-left: 38%;
}

.texas-grid {
  display: grid;
  grid-template-columns: repeat(5, 2.4fr);
  grid-template-rows: repeat(6, 2fr);
}
.one {
  grid-row: 1/3;
}
.two {
  grid-row: 1/2;
  grid-column: 2/6;
}
.grade {
  grid-row: 2/3;
}

.texas-grid > div {
  border: 1px solid;
}

.option:hover {
  cursor: pointer;
  background-color: #08979d;
}
.option__selected {
  background-color: #08979d;
}

.legend {
  display: flex;
  
  gap: 1em;
}
