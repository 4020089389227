form {
  display: flex;
  flex-direction: column;
}

.login-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #08979d;
  height: 100vh;
  text-align: center;
}
